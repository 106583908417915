import axios, { AxiosInstance, AxiosResponse } from "axios";
import { auth } from "@/utils/LtkAuth0";
const config = window.rs_kakao;

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export default class AxiosClient {
  public axiosClient: AxiosInstance;
  private constructor(access_token: string, id_token_claims: string) {
    let headers = {
      "Content-Type": "application/json"
    };
    if (access_token) {
      headers = Object.assign(headers, {
        Authorization: `Bearer ${access_token}`
      });
    }
    if (id_token_claims) {
      headers = Object.assign(headers, {
        "x-id-token": id_token_claims
      });
    }

    this.axiosClient = axios.create({
      baseURL: config.base_url,
      headers
    });

    this._initializeResponseInterceptor();
  }

  public static async withAccessToken(): Promise<AxiosClient> {
    const accessToken = await auth.getAccessToken();
    const idTokenClaims = (await auth.getIdTokenClaims())?.__raw || "";
    const api = new AxiosClient(accessToken, idTokenClaims);
    return api;
  }

  private _initializeResponseInterceptor = () => {
    this.axiosClient.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  private _handleError = (error: any) => Promise.reject(error);
}
