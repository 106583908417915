var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"form-container",attrs:{"fluid":"","ma-0":"","pa-0":""}},[_c('LTKHeader'),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"d-flex justify-center align-center flex-column mx-4"},[_c('v-card',{staticClass:"ma-3 py-4 rounded-lg border-top-card",attrs:{"outlined":"","max-height":"400","width":"800"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v("LTK 브랜드 킥오프 포맷 ")])],1),_c('form-field',{attrs:{"title":"성함","placeholder":"성함","rules":_vm.requiredRules,"type":"input"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('form-field',{attrs:{"title":"직책","placeholder":"직책","rules":_vm.requiredRules,"type":"input"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('form-field',{attrs:{"title":"이메일","placeholder":"이메일","rules":_vm.emailRules,"type":"input"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('form-field',{attrs:{"title":"브랜드명","placeholder":"브랜드명","rules":_vm.requiredRules,"type":"input"},model:{value:(_vm.brandName),callback:function ($$v) {_vm.brandName=$$v},expression:"brandName"}}),_c('form-field',{attrs:{"title":"브랜드 사이트 URL","placeholder":"브랜드 사이트 URL","rules":_vm.requiredRules,"type":"input"},model:{value:(_vm.retailerSite),callback:function ($$v) {_vm.retailerSite=$$v},expression:"retailerSite"}}),_c('form-field',{attrs:{"title":"플랫폼","type":"check-box","options":['3자 플랫폼 (네이버, 아마존, 등)', '자사몰']},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}}),_c('form-field',{attrs:{"title":"카테고리","options":[
          '패션 의류',
          '패션 악세서리',
          '스포츠',
          '뷰티',
          '라이프스타일'
        ],"type":"check-box","otherOption":"기타","rules":_vm.requiredRules},on:{"other-option-input":_vm.handleCategoryInput},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('form-field',{attrs:{"title":"연매출","options":['~1억', '1억~5억', '5억~10억', '10억 이상'],"type":"check-box"},model:{value:(_vm.sale),callback:function ($$v) {_vm.sale=$$v},expression:"sale"}}),_c('form-field',{attrs:{"title":"LTK에 대해 들어보신 적이 있으신가요?","subtitle":"다음 중에서 골라주세요","options":['KOL 추천', '인터넷', '동료', 'LTK 인스타그램'],"optionYes":"예","optionNo":"LTK에 대해 들어본 적이 없습니다","otherOption":"기타","rules":_vm.requiredRules},on:{"other-option-input":_vm.handleHeardLTKInput},model:{value:(_vm.heardLTK),callback:function ($$v) {_vm.heardLTK=$$v},expression:"heardLTK"}}),_c('form-field',{attrs:{"title":"소셜미디어에 브랜드를 홍보하거나 KOL과 협업한 경험이 있으신가요?","options":['예', '아직은 아니지만 계획이 있습니다.', '아니오'],"type":"check-box"},model:{value:(_vm.promotedBrand),callback:function ($$v) {_vm.promotedBrand=$$v},expression:"promotedBrand"}}),_c('form-field',{attrs:{"title":"다른 어필리에이트 네트워크와 브랜드 사이트가 연동되어 있나요?","subtitle":"다음 중에서 골라주세요","options":[
          'Partnerize',
          'Linkshare',
          'Impact',
          'Commission Junction',
          'Affiliate Window',
          'Shareasale'
        ],"optionNo":"아니오","optionYes":"예","otherOption":"기타","rules":_vm.requiredRules},on:{"other-option-input":_vm.handleIntergratedInput},model:{value:(_vm.intergrated),callback:function ($$v) {_vm.intergrated=$$v},expression:"intergrated"}}),_c('form-field',{attrs:{"title":"저희와 함께 일하고 싶은 이유:","options":['CPS', '인플루언서 콜라보레이션', '둘 다 해당됩니다.'],"type":"check-box"},model:{value:(_vm.interested),callback:function ($$v) {_vm.interested=$$v},expression:"interested"}}),(_vm.invalid)?_c('div',{staticClass:"mb-2 red--text"},[_vm._v(" 모든 빈칸을 채워주세요. ")]):_vm._e(),_c('v-btn',{staticClass:"mt-3 mb-6 white--text",attrs:{"title":"","color":"black","width":"150","height":"45","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" 제출하기 ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }