





















































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import FormField from "@/components/FormField.vue";
import LTKHeader from "@/components/LTKHeader.vue";
import FormApi from "@/utils/api/form";
import { VuetifyFieldValidationRules } from "@/utils/constants";

@Component({
  components: { FormField, LTKHeader }
})
export default class KickOffForm extends Vue {
  public loading = false;
  public invalid = false;
  public name = "";
  public title = "";
  public brandName = "";
  public retailerSite = "";
  public website = "";
  public category = "";
  public email = "";
  public sale = "";
  public heardLTK = "";
  public promotedBrand = "";
  public intergrated = "";
  public interested = "";

  public categoryInput = "";
  public heardLTKInput = "";
  public intergratedInput = "";

  @Ref() readonly form!: HTMLFormElement;

  get emailRules(): VuetifyFieldValidationRules {
    return [
      (v: string) => !!v || "모든 빈칸을 채워주세요.",
      (v: string) => /.+@.+/.test(v) || "이메일이 정확하지 않습니다."
    ];
  }

  get requiredRules(): VuetifyFieldValidationRules {
    return [(v: string) => !!v || "모든 빈칸을 채워주세요."];
  }

  public handleCategoryInput(input: string) {
    this.categoryInput = input;
  }

  public handleHeardLTKInput(input: string) {
    this.heardLTKInput = input;
  }

  public handleIntergratedInput(input: string) {
    this.intergratedInput = input;
  }

  public async submit() {
    if (!this.form.validate()) {
      return;
    }

    this.invalid = false;

    if (
      !this.category ||
      !this.website ||
      !this.sale ||
      !this.heardLTK ||
      !this.promotedBrand ||
      !this.intergrated ||
      !this.interested
    ) {
      this.invalid = true;
      return;
    }

    const data: KickOffFormData = {
      name: this.name,
      title: this.title,
      brand_name: this.brandName,
      retailer_site: this.retailerSite,
      platform: this.website,
      category: this.categoryInput
        ? `${this.category}: ${this.categoryInput}`
        : this.category,
      email: this.email,
      annual_sales: this.sale,
      heard_ltk: this.heardLTKInput
        ? `${this.heardLTK}: ${this.heardLTKInput}`
        : this.heardLTK,
      promoted_brand: this.promotedBrand,
      intergrated: this.intergratedInput
        ? `${this.intergrated} : ${this.intergratedInput}`
        : this.intergrated,
      interested: this.interested
    };
    try {
      this.loading = true;
      await FormApi.submitForm(data);
      this.$router.push("/kick-off/result");
    } catch (error) {
      return;
    } finally {
      this.loading = false;
    }
  }
}
