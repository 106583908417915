























import { Vue, Component } from "vue-property-decorator";
import LTKHeader from "@/components/LTKHeader.vue";

@Component({ components: { LTKHeader } })
export default class SubmitResult extends Vue {}
