import AxiosClient from "@/utils/api/axiosClient";

class FormApi {
  public submitForm = async (data: KickOffFormData) => {
    const api = await AxiosClient.withAccessToken();
    await api.axiosClient.post<KickOffFormData>("/kickoff-form", {
      ...data
    });
  };
}

export default new FormApi();
