






















































































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class FormField extends Vue {
  @Prop(String) public title!: string;
  @Prop(String) public subtitle!: string;
  @Prop(String) public type!: string;
  @Prop(String) public placeholder!: string;
  @Prop(String) public value!: string;
  @Prop() public options!: string[];
  @Prop(String) public optionYes!: string;
  @Prop(String) public optionNo!: string;
  @Prop() public otherOption!: boolean;
  @Prop() public rules!: [];

  public menu = false;
  public answer = "";

  public handleNoAnswer($event: Event) {
    if (this.answer == this.optionNo) {
      this.$emit("input", $event);
    }
    this.$emit("other-option-input", "");
  }

  public onOptionChange($event: Event) {
    this.$emit("input", $event);
    this.$emit("other-option-input", "");
  }
}
